<template lang="pug">
v-select(
  :value="value"
  :label="label"
  :disabled="disabled"
  :item-value="itemValue"
  :item-text="itemText" 
  :loading="loading"
  :items="items"
  @input="_valueClicked"
  @change="$emit('change')"
  :multiple="multiple"
  :clearable="clearable"
  :error="error"
  :data-test="testName"
  :error-messages="errorMessages"
  :hideDetails="hideDetails"
  @mouseup="closeSelectIfOpen($refs.select, {enabled: closeOnInputClick})"
  ref="select"
  :menu-props="{contentClass: 'menu-with-scroll-visible'}"
)
  template(v-slot:append v-if="showSelectedIcon && hasValue")
    v-icon(v-if="showClearBtn" @click="clear") {{ deletedIcon }}
    v-icon(color="primary") {{ selectedIcon }}
  template(v-slot:prepend-item v-if="showCloseMenuBtn")
    select-close-btn(@click.native="closeSelect($refs.select)")
</template>

<script>
import ErrMixin from "@/mixins/errors.mixin"
import AutocompleteMixin from "@/app/admin/mixins/autocomplete.mixin"
export default {
  name: 'FilterCourseLocation',
  mixins: [ErrMixin, AutocompleteMixin],

  props: {
    value: {
      required: true
    },
    getItems: {
      type: Function
    },
    itemText: {
      type: String,
      default: ''
    },

    itemValue: {
      type: String,
      default: ''
    },
    label: String,
    disabled: Boolean,
    error: Boolean,
    errorMessages: String,
    multiple: Boolean,
    clearable: Boolean,
    showClearBtn: Boolean,
    showSelectedIcon: Boolean,
    selectedIcon: {
      type: String,
      default: 'mdi-check-circle'
    },
    deletedIcon: {
      type: String,
      default: 'mdi-close '
    },
    hideDetails: Boolean,
    redirectName: String,
    testName: String,
    dataTest: String,
    showCloseMenuBtn: Boolean,
    closeOnInputClick: Boolean,
    list: Array
  },

  data: () => ({
    loading: false,
    items: []
  }),


  mounted() {
    if(this.list) this.items = this.list
    else this.loadData()
  },

  methods: {
    clear() {
      this.$emit('input', null)
    },
    async loadData() {
      try {
        this.loading = true
        let res = await this.getItems({cache: true})
        this.items = res
        this.loading = false
        this.$emit('itemsLoaded')
      } catch (error) {
        const params = {}
        if(this.redirectName) params.redirectName = this.redirectName
        this.loading = false
        this.processError(error, params)
      }
    },
    _valueClicked(e) {
      this.$emit('input', e)
    }
  },

  computed: {
    hasValue() {
      if(Array.isArray(this.value) && this.value.length) return true
      if(!(Array.isArray(this.value)) && this.value) return true
      return false
    }
  },

  components: {
    selectCloseBtn: () => import('@/app/admin/components/SelectCloseBtn.vue'),
  }
}
</script>
